.game-card {
  /* border: 1px solid black; */
  width: 375px;
  margin: 200px auto;
  background-color: #222;
  color: #eee;
  padding: 0 20px 0 20px;
  border-radius: 12px;
  box-shadow: 2px 2px 30px #444;
  transition: 300ms;
}

.game-card:hover {
  transform: scale(1.2);
}

.game-card-title {
  font-size: 18px;
}

.game-card-list > li {
  list-style-type: none;
  margin-bottom: 9px;
}

.game-card-link:link,
.game-card-link:visited {
  color: orange;
  text-decoration: none;
}

.game-card-link:hover {
  color: #eee;
}
